import React from 'react';
import { useEffect,useState, useRef } from 'react';
import { Box, Typography, Grid, Container, List, ListItem, ListItemIcon, ListItemText , Card, CardContent, Button, CardMedia,CardActions} from '@mui/material';
import Divider from '@mui/material/Divider';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import businessIntelligencelogo from './images/businessintelligence.jpg';
import primaryMarketResearchlogo from './images/primaymarket.jpg';
import businessConsultinglogo from './images/bussinessconsulating.jpg';
import marketReasearchlogo from './images/primaymarket.webp'
import AOS from 'aos';
import 'aos/dist/aos.css';
// ... other imports


const OfferSection = ({offerRef}) => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []);

  const exportConsultinglist = [
    "Strategic planning and advisory services",
    "Problem-solving and decision-making support",
    "Industry-specific expertise and insights",
    "Customized solutions tailored to client needs",
  ]

  const marketresearchlist = [
    "Market trend analysis and forecasting",
    "Consumer behavior research and segmentation",
    "Competitor analysis and benchmarking",
    "Market opportunity identification",
  ]

  const analyticalReportslist = [
    "Data-Driven Decision-Making",
    "Strategic Recommendations",
    "Optimization Strategies",
    "Customized Solutions",
  ]

  const CorporateTrainingslist = [
    "Skill Enhancement",
    "Expert Instructors",
    "Flexible Delivery Formats",
    "Customized Programs",
  ]

  const cardRef = useRef(null);

  return (
    <Container maxWidth="lg" style={{ position: 'relative', padding: '40px 35px' }}>
      <Box sx={{padding:'10px  0px', display:'flex', flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        <Typography variant="h3" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif' }}><b>OUR SERVICES</b></Typography>
      </Box>
      <br/>
      <br/>
      <br/>
      <Grid container spacing={3} alignItems="stretch" justifyContent="center" sx={{color:'#272D4E'}}>
              <Grid item xs={12} sm={6} md={6}>
                <Card ref={cardRef} sx={{ maxWidth: 500,paddingBottom:1, minHeight: 652, maxHeight: 'fit-content',
              }}>
                  <CardMedia
                    sx={{ height: 200 }}
                    image={businessConsultinglogo}
                    title="green iguana"
                  />
                  <CardContent sx={{padding:'20px'}}>
                    <Typography gutterBottom variant="h5" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif', fontSize:'27px' }} component="div">
                      Expert Consultation
                    </Typography>
                    <br/>
                    <Typography variant="body1" color="#272D4E" sx={{fontFamily:'Montserrat, sans-serif', textAlign:'justify'}}>
                      Our team of seasoned consultants offers tailored guidance and strategic advice to navigate complex challenges and achieve your business goals effectively.
                    </Typography>
                    {/* {showMore ?<> */}
                    <br/>
                    <Typography  variant="h6" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif' }} >Key Offerings</Typography>
                      <List style={{ color: '#272D4E',fontFamily:'Montserrat, sans-serif' }}>
                        {exportConsultinglist.map((text, index) => (
                          <ListItem key={index}>
                          <ListItemIcon>
                            <DoubleArrowIcon sx={{ color: "#5C4033" }}/>
                          </ListItemIcon>
                          <ListItemText>
                            <Typography  variant="body2" style={{ color: '#272D4E',fontFamily:'Montserrat, sans-serif' }} >{text}</Typography>
                          </ListItemText>
                        </ListItem>
                        ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Card sx={{ maxWidth: 500,paddingBottom:1,  minHeight: 652, maxHeight: 'fit-content',
                }}>
                  <CardMedia
                    sx={{ height: 200 }}
                    image={marketReasearchlogo}
                    title="green iguana"
                  />
                  <CardContent sx={{padding:'20px'}}>
                    <Typography gutterBottom variant="h5" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif', fontSize:'25px' }} component="div">
                      Market Research
                    </Typography>
                    <br/>
                    <Typography variant="body1" color="#272D4E" sx={{fontFamily:'Montserrat, sans-serif', textAlign:'justify'}}>
                    Gain invaluable insights into market trends, consumer behavior, and competitor analysis through our meticulous 
                    research, empowering you to make informed decisions and stay ahead of the curve.
                    </Typography>
                    <br/>
                    <Typography  variant="h6" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif' }} >Key Offerings</Typography>
                      <List style={{ color: '#272D4E',fontFamily:'Montserrat, sans-serif' }}>
                        {marketresearchlist.map((text, index) => (
                          <ListItem key={index}>
                          <ListItemIcon>
                            <DoubleArrowIcon sx={{ color: "#5C4033" }}/>
                          </ListItemIcon>
                          <ListItemText>
                            <Typography  variant="body2" style={{ color: '#272D4E',fontFamily:'Montserrat, sans-serif' }} >{text}</Typography>
                          </ListItemText>
                        </ListItem>
                        ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              </Grid>
              <br/>
              <br/>
              <br/>
              <Grid container spacing={2} alignItems="stretch" justifyContent="center" sx={{color:'#272D4E'}}>
              <Grid item xs={12} sm={6} md={6}>
                <Card sx={{ maxWidth: 500,paddingBottom:1,  minHeight: 692, maxHeight: 'fit-content',
                }}>
                  <CardMedia
                    sx={{ height: 200 }}
                    image={businessIntelligencelogo}
                    title="green iguana"
                  />
                  <CardContent sx={{padding:'20px'}}>
                    <Typography gutterBottom variant="h5" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif', fontSize:'27px' }} component="div">
                      Analytical Reports
                    </Typography>
                    <br/>
                    <Typography variant="body1" color="#272D4E" sx={{fontFamily:'Montserrat, sans-serif', textAlign:'justify'}}>
                    Unlock the power of data-driven decision-making with our comprehensive analytical reports, providing actionable 
                    insights and strategic recommendations to drive business growth and optimization.
                    </Typography>
                    <br/>
                    <Typography  variant="h6" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif' }} >Key Offerings</Typography>
                      <List style={{ color: '#272D4E',fontFamily:'Montserrat, sans-serif' }}>
                        {analyticalReportslist.map((text, index) => (
                          <ListItem key={index}>
                          <ListItemIcon>
                            <DoubleArrowIcon sx={{ color: "#5C4033" }}/>
                          </ListItemIcon>
                          <ListItemText>
                            <Typography  variant="body2" style={{ color: '#272D4E',fontFamily:'Montserrat, sans-serif' }} >{text}</Typography>
                          </ListItemText>
                        </ListItem>
                        ))}
                    </List> 
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Card sx={{ maxWidth: 500,paddingBottom:1,  minHeight: 692, maxHeight: 'fit-content',
                }}>
                  <CardMedia
                    sx={{ height: 200 }}
                    image={primaryMarketResearchlogo}
                    title="green iguana"
                  />
                  <CardContent sx={{padding:'20px'}}>
                    <Typography gutterBottom variant="h5" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif', fontSize:'27px' }} component="div">
                      Corporate Trainings
                    </Typography>
                    <br/>
                    <Typography variant="body1" color="#272D4E" sx={{fontFamily:'Montserrat, sans-serif', textAlign:'justify'}}>
                    Elevate your team's skills and performance with our customized corporate training programs, designed to enhance
                     productivity, foster innovation, and cultivate a culture of continuous learning within your organization.

                    </Typography>
                    <br/>
                    <Typography  variant="h6" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif' }} >Key Offerings</Typography>
                      <List style={{ color: '#272D4E',fontFamily:'Montserrat, sans-serif' }}>
                        {CorporateTrainingslist.map((text, index) => (
                          <ListItem key={index}>
                          <ListItemIcon>
                            <DoubleArrowIcon sx={{ color: "#5C4033" }}/>
                          </ListItemIcon>
                          <ListItemText>
                            <Typography  variant="body2" style={{ color: '#272D4E',fontFamily:'Montserrat, sans-serif' }} >{text}</Typography>
                          </ListItemText>
                        </ListItem>
                        ))}
                    </List> 
                  </CardContent>
                </Card>
              </Grid>
        </Grid>
    </Container>
  );
};

export default OfferSection;
