import React from 'react';
import { useEffect } from 'react';
import { Link,Container, Typography, Button, Box ,Grid, useTheme, useMediaQuery } from '@mui/material';
import NavRathLogo from './RATHcha.png'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useLocation,useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const useStyles = makeStyles((theme) => ({
    TextField: {
        backgroundColor: 'white',
      '& .MuiInputBase-input::placeholder': {
        color: '#5C4033 !important',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#5C4033',
        },
        '&:hover fieldset': {
          borderColor: '#5C4033',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#5C4033',
        },
        '& input::placeholder': {
          color: '#5C4033',
        },
      },
    },
    lastfooter: {
        display:'flex',
        alignItems:'center',
        justifyContent:'space-evenly',
    },
    lastfooter1: {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'space-evenly',
    }
  }));

const Contact = ({ homeRef, aboutRef, offerRef,contactRef ,inputValue1, setInputValue1}) => {
    const classes = useStyles();
    const theme = useTheme();

    const currentYear = new Date().getFullYear();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const scrollToRef = (ref) => {
        window.scrollTo({
          top: ref.current.offsetTop,
          behavior: 'smooth'
        });
      };

    const navigate = useNavigate();
    const location1 = useLocation();
    const currentRoute = location1.pathname;


    useEffect(() => {
        AOS.init({
          duration: 2000,
        });
      }, []);

      const handleRedirectToHome = () => {
        navigate('/');
      }
      const handleRedirectToContactc = () => {
        navigate(`/contactclient`);
      };
      const handleRedirectToContacta = () => {
        navigate(`/contactadvisor`);
      };
      const handleRedirectToContact = () => {
        navigate(`/contact`);
      };

  return (
    <div style={{ position:'relative' ,py:'20px'}}>
        <Box sx={{
            backgroundColor: '#5C4033', 
            color: 'white', 
            boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.1)', 
            textAlign: 'center',
            padding: '60px 0px 0px 0px'
        }}>
            <Container maxWidth="lg" >
            <Grid container justifyContent="center" alignItems="flex-start" >
                <Grid item xs={12} sm={5} md={6} pb={4} pl={2}>
                    <Box sx={{display:'flex',flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start'}}>
                        <img src={NavRathLogo} alt="RATH logo" width='250px' height='80px'></img>
                        <br/>
                        <Button variant="outlined" onClick={() => { handleRedirectToContactc(); setTimeout(() => scrollToRef(contactRef), 100);}} 
                        style={{ fontFamily:'Montserrat, sans-serif', color: 'white', width:'200px', borderColor: 'white', '&:hover': { borderColor: 'white', backgroundColor: 'white'}  }} >
                            Become a Client
                        </Button>
                        <br/>
                        <Button variant="outlined" onClick={() => { handleRedirectToContacta(); setTimeout(() => scrollToRef(contactRef), 100); }}
                        style={{ fontFamily:'Montserrat, sans-serif', color: 'white', width:'200px', borderColor: 'white', '&:hover': { borderColor: 'white', backgroundColor: 'white'}  }} >
                            Become an expert 
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3} md={2} pb={4} pl={2}>
                    <Box sx={{display:'flex',flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start'}}>
                        <Typography variant="h5" color="white" pb={1} fontWeight={600}>SITE MAP</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{cursor:'pointer', fontFamily:'Montserrat, sans-serif'}} {...currentRoute === '/contact' ? {onClick: () => { handleRedirectToHome(); setTimeout(() => scrollToRef(homeRef), 200); }} : {onClick: () => scrollToRef(homeRef)}}>Home</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{cursor:'pointer', fontFamily:'Montserrat, sans-serif'}} {...currentRoute === '/contact' ? {onClick: () => { handleRedirectToHome(); setTimeout(() => scrollToRef(aboutRef), 200); }} : {onClick: () => scrollToRef(homeRef)}}>About</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{cursor:'pointer', fontFamily:'Montserrat, sans-serif'}} {...currentRoute === '/contact' ? {onClick: () => { handleRedirectToHome(); setTimeout(() => scrollToRef(offerRef), 200); }} : {onClick: () => scrollToRef(homeRef)}}>Services</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{cursor:'pointer', fontFamily:'Montserrat, sans-serif'}} onClick={() => { handleRedirectToContact(); setInputValue1(''); setTimeout(() => scrollToRef(contactRef), 100); }}>Contact</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4} pb={4} pl={2}>
                    <Box sx={{display:'flex',flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start'}}>
                    <Typography variant="h5" color="white" align="center" pb={1} fontWeight={600} sx={{fontFamily:'Montserrat, sans-serif'}}>CONTACT US</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{fontFamily:'Montserrat, sans-serif'}}>Address</Typography>
                        <Typography variant="subtitle1" color="white" align="center" sx={{fontFamily:'Montserrat, sans-serif'}}>Rath Research Head  Office : </Typography> 
                        <Typography variant="subtitle1" color="white" align="left" mb={1}  sx={{fontFamily:'Montserrat, sans-serif'}}> 
                        Orchid Techspace, STPI-Bengaluru, No.76 & 77, 6th Floor,Cyber Park,Electronics City, Hosur Road, Bengaluru, 
                        Karnataka - 560100</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{fontFamily:'Montserrat, sans-serif'}}>Telephone</Typography>
                        <Link href="tel:+918004006979" underline="none">
                            <Typography variant="subtitle1" color="white" align="center" mb={1} sx={{fontFamily:'Montserrat, sans-serif'}}>+918004006979</Typography>
                        </Link>
                        <Typography variant="h6" color="white" align="center" sx={{fontFamily:'Montserrat, sans-serif'}}>Email</Typography>
                        <Link href="mailto:contact@rathresearch.com" underline="none">
                        <Typography variant="subtitle1" color="white" align="center" mb={1} sx={{ fontFamily: 'Montserrat, sans-serif' }}>
                            contact@rathresearch.com
                        </Typography>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            </Container>            
            <Box component="footer" py={3}  sx={{backgroundColor:'rgba(0,0,0,0.2)'}} >
                <Box px={3} className={isMobile ? classes.lastfooter1 : classes.lastfooter}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography variant="body2" color="lightgrey" align="center" 
                            sx={{fontFamily:'Montserrat, sans-serif'}}>
                                Copyright © RATH RESEARCH {currentYear}. All Rights Reserved.
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={2} md={1}>
                            <Typography variant="body2" color="lightgrey" align="center" 
                            sx={{fontFamily:'Montserrat, sans-serif'}}>Bangalore</Typography>
                        </Grid>
                        <Grid item xs={4} sm={2} md={1}>
                            <Typography variant="body2" color="lightgrey" align="center"
                            sx={{fontFamily:'Montserrat, sans-serif'}}>Dubai</Typography>
                        </Grid>
                        <Grid item xs={4} sm={2} md={1}>
                            <Typography variant="body2" color="lightgrey" align="center"
                            sx={{fontFamily:'Montserrat, sans-serif'}}>New York</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={1}>
                            <Typography variant="body2" color="lightgrey" align="center"
                            sx={{fontFamily:'Montserrat, sans-serif'}}>Tokyo</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
     </div>
  );
};

export default Contact;