import React from 'react';
import { useEffect } from 'react';
import { Container, Typography, Button, Stack, Grid, IconButton, useMediaQuery,useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RathLogo from './DALL·E 2024.png';
import bgimage from './bgimage.webp'
import bgimage1 from './bgimage.jpg'
import bgvideo from './rathbgvideo.mp4'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { styled } from '@mui/system';
import ExpandCircleDownSharpIcon from '@mui/icons-material/ExpandCircleDownSharp';


const CustomButton = styled(Button)({
  fontFamily: 'Montserrat, sans-serif',
  color: '#fcead7',
  borderColor: '#fcead7',
  '&:hover': {
    backgroundColor: '#fcead7',
    borderColor: '#fcead7',
    color: '#5C4033',
  }
});

function HomeSection({aboutRef, contactRef, inputValue1, setInputValue1 }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    });
  };

  const boxPatternStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${bgimage1})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: isMobile ? '61px' : '0px',
    width: '100%',
    maxHeight:'400vh',
    height:'100%',
    zIndex: -1
  };

  const videoBackgroundStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6))`,
    position: 'absolute',
    top: isMobile ? '61px' : '0px',
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    objectFit: 'cover',
  };

  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []);

  const navigate = useNavigate();

  const handleRedirectToContact = () => {
    navigate(`/contactadvisor`);
  };

  const handleRedirectToContactClient = () => {
    navigate(`/contactclient`);
  };

  return (
    <>
    <div style={boxPatternStyle} ></div>
    <Container maxWidth="lg" style={{ position:'relative',top: isMobile? '70px' :'40px', padding: '60px 35px 0px 35px', height: isMobile? 'fit-content':'inherit'}}>
      <Grid container spacing={3}  alignItems="center" justifyContent="center">
        <Grid item xs={12} md={12} style={{ textAlign: 'center',color:'#5C4033', display:'flex',height:'90vh', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
          <Typography variant="h1" gutterBottom sx={{fontWeight:600, fontSize: isMobile ? '35px' : '40px', fontFamily:'Montserrat, sans-serif',background: 'linear-gradient(to right, #e6b785, #fcead7)',WebkitBackgroundClip: 'text',WebkitTextFillColor: 'transparent' }}>
            RATH RESEARCH - INTELLIGENCE TO DRIVE GROWTH
          </Typography>
          <Typography variant="subtitle1" color="#fcead7" paragraph textAlign={'center'} mr={2} 
          sx={{fontFamily:'Montserrat, sans-serif', fontSize: isMobile ? '12px' : '1rem'}}>
            RATH Research is your dedicated business intelligence partner. We empower organizations to make confident strategic 
            decisions through unparalleled market insights. Our integrated approach combines cutting-edge AI and expert human analysis
             to deliver tailored intelligence that drives growth. Become an insights-driven organization with RATH.
          </Typography>
          <br/>
          <Stack direction="row" spacing={2}>
            <CustomButton variant="outlined" onClick={() => { handleRedirectToContactClient(); setInputValue1('Become a RATH client'); }}>
              Become a Client
            </CustomButton>
            <CustomButton variant="outlined" onClick={() => { handleRedirectToContact(); setInputValue1('Become a RATH advisor/ consultant/ expert'); }}>
              Become an expert 
            </CustomButton>
            <br/>
          </Stack>
          <br/>
          <br/>
          {!isMobile ? <br/> :''}
          <IconButton
          size="large"
          sx={{
            animation: 'bounce 2s infinite',
            '@keyframes bounce': {
              '0%, 100%': { transform: 'translateY(0)' },
              '50%': { transform: 'translateY(-20px)' },
            },
            height: '70px',
            width: '70px',
          }}
          onClick={() => scrollToRef(aboutRef)}
        >
          <ExpandCircleDownSharpIcon sx={{ color: '#fcead7', fontSize: 'inherit' ,height: '60px', width: '60px', }} />
        </IconButton>
        </Grid>
      </Grid>
      {/* </div> */}
    </Container>
    </>
  );
}

export default HomeSection;
