import React from 'react';
import { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, useTheme, useMediaQuery, Menu, MenuItem, Backdrop, Link } from '@mui/material';
import NavRathLogo from './RATHcha.png'
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Turn as Hamburger } from 'hamburger-react';


const useStyles = makeStyles((theme) => ({
    root: {
    flexGrow: 1,
 },
 navbarDisplayFlex: {
    display: 'flex',
    justifyContent: 'center',
 },
 navbarDisplayFlex1: {
    display: 'flex',
    justifyContent: 'space-between',
 },
 navLink: {
    textDecoration: 'none',
 },
 menuPaper: {
    width: '99%', 
  },
  menuItem: {
    justifyContent: 'center',
  },
  imagelogo: {
    position: 'absolute', 
    left: '120px',
    display:'flex', 
    alignItems: 'center',
    width: 'fit-content', 
    justifyContent:'space-around'
  },
  imagelogo1: {
    display:'flex', 
    alignItems: 'center',
    width: 'fit-content', 
    justifyContent:'space-around'
  },
}));

const NavbarC = ({ homeRef, aboutRef, offerRef,contactRef1 }) => {
 const classes = useStyles();
 const theme = useTheme();
 const isMobile = useMediaQuery(theme.breakpoints.down('md'));

 const [isOpen, setOpen] = useState(false)

 const [anchorEl, setAnchorEl] = useState(null);

 const handleMenu = (event) => {
    console.log("Menu opened");
    setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
    console.log("Menu closed")
    // scrollToRef(ref)
    setOpen(false)
    setAnchorEl(null);
 };

 const scrollToRef = (ref) => {
  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth'
  });
};

const navigate = useNavigate();

const handleRedirectToHome = () => {
  navigate('/');
};

 return (
    <div className={classes.root}>
      <AppBar position="fixed" sx={{ bgcolor: "#FFFFFF" }}>
        <Toolbar className={isMobile ? classes.navbarDisplayFlex1 : classes.navbarDisplayFlex}>
          <Box className={isMobile ? classes.imagelogo1 : classes.imagelogo} onClick={handleRedirectToHome}>
            <img src={NavRathLogo} alt="RATH logo" width='100%' height='40px' style={{cursor:'pointer'}}></img>
          </Box>
          {isMobile ? (
            <>
            <Button style={{}} onClick={handleMenu}>
                <Hamburger toggled={isOpen} toggle={setOpen}  color="#5C4033" size={20}/>
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                    className: classes.menuPaper, // Add this line to apply width style
                  }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{fontFamily:'Montserrat, sans-serif'}}
              >
                <MenuItem onClick={() =>{ handleRedirectToHome();setTimeout(() => scrollToRef(homeRef), 200);handleClose()}}>Home</MenuItem>
                <MenuItem onClick={() =>{ handleRedirectToHome();setTimeout(() => scrollToRef(aboutRef), 200);handleClose()}}>About</MenuItem>
                <MenuItem onClick={() =>{ handleRedirectToHome();setTimeout(() => scrollToRef(offerRef), 200);handleClose()}}>Services</MenuItem>
                <MenuItem onClick={() =>{ scrollToRef(contactRef1);handleClose()}}>Contact</MenuItem>
            </Menu>
            </>
          ) : (
            <nav style={{width:'480px',display: 'flex' , justifyContent:'space-evenly'}}>
              {/* <ThemeProvider theme={theme}> */}
                <Button className={classes.navLink} style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif',fontWeight:'bold' }}  onClick={() =>{ handleRedirectToHome();setTimeout(() => scrollToRef(homeRef), 200);}}> Home </Button>
                <Button className={classes.navLink} style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif',fontWeight:'bold' }} onClick={() =>{ handleRedirectToHome();setTimeout(() => scrollToRef(aboutRef), 200);}}> About </Button>
                <Button className={classes.navLink} style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif',fontWeight:'bold' }} onClick={() =>{ handleRedirectToHome();setTimeout(() => scrollToRef(offerRef), 200);}}> Services </Button>
                <Button className={classes.navLink} onClick={() => scrollToRef(contactRef1)}
                style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif',fontWeight:'bold'}}>  Contact</Button>
                {/* </ThemeProvider> */}
            </nav>
          )}
        </Toolbar>
      </AppBar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={Boolean(anchorEl)}
        onClick={handleClose}
      />
    </div>
 );
};

export default NavbarC;