import React from 'react';
import { useEffect } from 'react';
import { Container, Typography, Box, Grid, Divider} from '@mui/material';
import waveup from './waveup.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './about.css'

function Aboutsection() {


  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []);

  return (
    <>
      <div style={{backgroundColor:'#fcead7',marginTop:'18px'}}>
        <Container maxWidth="lg" mt={1} style={{ position:'relative', padding: '40px 35px' , textAlign:'center'}}>
            <Box sx={{padding:'10px  0px', display:'flex', flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
              <Typography  variant="h3" style={{ color: '#5C4033', fontFamily:'Montserrat, sans-serif' }}><b>ABOUT US</b></Typography>
            </Box>
            <br/>
            <br/>
            <Grid container  spacing={3} alignItems="flex-start" justifyContent="center" sx={{color:'#272D4E'}}>
              <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
              <Typography  variant="h5" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif' }} pb={1}><b>OUR MISSION</b></Typography>
                <Typography variant="subtitle1" textAlign={'justify'} sx={{fontFamily:'Montserrat, sans-serif'}}>
                  At RATH Research, we strive to equip organizations with premier business intelligence and primary market research 
                  to fulfill their strategic ambitions. As your trusted ally, we deliver the clarity needed to navigate decision-making 
                  with assuredness.
                </Typography>
                <br/>
                <br/>
                <Typography  variant="h5" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif' }} pb={1}><b>OUR TEAM</b></Typography>
                <Typography variant="subtitle1" textAlign={'justify'} sx={{fontFamily:'Montserrat, sans-serif'}}>
                Comprising experts with a rich history in corporate strategy and market intelligence, our team has honed their skills 
                within some of the foremost global companies. With a profound understanding of various industries, functions, and 
                regions, our analysts excel at converting complex data into meaningful insights and strategic actions.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
                {/* <FirmwareIcon id="logo1" style={{ maxWidth: '100%', height: 'auto' }}/> */}
                <Typography  variant="h5" style={{ color: '#5C4033',fontFamily:'Montserrat, sans-serif' }} pb={1}><b>OUR STORY</b></Typography>
                <Typography variant="subtitle1" textAlign={'justify'} sx={{fontFamily:'Montserrat, sans-serif'}}>
                  Established in 2022 by seasoned professionals, RATH Research sprang from the recognition of a gap in integrated 
                  business intelligence. Merging AI's breadth and agility with human acumen, we offer unparalleled strategic insight. 
                  Our legacy lies in directing multifaceted strategies for world-leading firms, a foundation that, combined with our 
                  innovative technology, positions us as indispensable counselors. We relentlessly innovate in business intelligence, 
                  creating sophisticated tools for today's ever-evolving market demands. Our commitment to your success is unwavering, 
                  as we continuously endeavor to propel our clients forward with exceptional intelligence.
                </Typography>
              </Grid>
            </Grid>
    </Container>
    </div>
    <section>
    <div className='curve'></div>
    </section>
    <br/>
    </>
  );
}

export default Aboutsection;