import './App.css';
import React, { useRef,useEffect,useState } from 'react';
import Navbar from './navabar/navabar';
import NavbarC from './contact/navabar'
import HomeSection from './home/home';
import Aboutsection from './about/about';
import OfferSection from './offer/offer';
import Contact from './contact/contact';
// import Login from './login/login';
import Contact1 from './contact/contact1'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import whatsappIcon from './whatsapp.png'
import ContactClient from './contact/contactclient';
import ContactAdvisor from './contact/contactadvisor';

function App() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const offerRef = useRef(null);
  const clientRef = useRef(null);
  const contactRef = useRef(null);

  const [inputValue1, setInputValue1] = useState('');

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/contact" element={<>
            <NavbarC homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef1={contactRef}/>
            <div ref={contactRef} style={{ width: '100%', height: '30px' }}></div>
                <br />
            <Contact1 id="contact" homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef={contactRef}  inputValue1={inputValue1} setInputValue1={setInputValue1} />
          </>} />
          <Route path="/contactclient" element={<>
            <NavbarC homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef1={contactRef}/>
            <div ref={contactRef} style={{ width: '100%', height: '30px' }}></div>
                <br />
            <ContactClient id="contact" homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef={contactRef}  inputValue1={inputValue1} setInputValue1={setInputValue1} />
          </>} />
          <Route path="/contactadvisor" element={<>
            <NavbarC homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef1={contactRef}/>
            <div ref={contactRef} style={{ width: '100%', height: '30px' }}></div>
                <br />
            <ContactAdvisor id="contact" homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef={contactRef}  inputValue1={inputValue1} setInputValue1={setInputValue1} />
          </>} />
          <Route
            path="/"
            element={
              <>
                <Navbar homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef={contactRef} inputValue1={inputValue1} setInputValue1={setInputValue1}/>
                <div ref={homeRef}><HomeSection aboutRef={aboutRef} contactRef={contactRef} inputValue1={inputValue1} setInputValue1={setInputValue1}/></div>
                <div ref={aboutRef} style={{ width: '100%', height: '0px' }}></div>
                <br />
                <Aboutsection />
                <div ref={offerRef} style={{ width: '100%', height: '10px' }}></div>
                <br />
                <OfferSection />
                <div ref={contactRef} style={{ width: '100%', height: '10px' }}></div>
                <br />
                <Contact id="contact" homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef={contactRef} inputValue1={inputValue1} setInputValue1={setInputValue1} />
              </>
            }
          />
        </Routes>
        {/* <div className="whatsapp-icon">
          <a href="https://wa.me/your-whatsapp-number" target="_blank" rel="noopener noreferrer">
            <img src={whatsappIcon} alt="WhatsApp" width="50" height="50" />
          </a>
        </div> */}
      </div>
    </Router>
  );
}

export default App;



// import  { React, useRef }  from "react";
// import { Routes, Route, Navigate  } from "react-router-dom";
// import './App.css';
// import routes from './route.js';
// import Mainpage from './mainpage'
// import Navbar from './navabar/navabar';
// import HomeSection from './home/home';
// import Aboutsection from './about/about';
// import OfferSection from './offer/offer';
// import Contact from './contact/contact';


// export default function App() {
//   const homeRef = useRef(null);
//   const aboutRef = useRef(null);
//   const offerRef = useRef(null);
//   const clientRef = useRef(null);
//   const contactRef = useRef(null);


//   // const { pathname } = useLocation();
//   const getRoutes = (allRoutes) =>
//     allRoutes.map((route) => {
//       if (route.collapse) {
//         return getRoutes(route.collapse);
//       }
//       if (route.route) {
//         return <Route exact path={route.route} element={route.component} key={route.key} />;
//       }

//       return null;
//     });
//   return (
//     <div className="App">
//       <Routes>
//       {getRoutes(routes)}
//         {/* <Route path="/bot/1/" component={< Chatbot/>} /> */}
//         <Route path="/" component={<>
//           <Navbar homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef={contactRef}/>
//           <div ref={homeRef}><HomeSection contactRef={contactRef}/></div>
//           <div ref={aboutRef} style={{width:'100%',height:'10px'}}></div>
//           <br/>
//           <Aboutsection />
//           <div ref={offerRef}style={{width:'100%', height:'10px'}}></div>
//           <br/>
//           <OfferSection />
//           <div ref={contactRef}style={{width:'100%', height:'10px'}}></div>
//           <br/>
//           <Contact id="contact"  contactRef={contactRef}/>
//         </>} />
//         <Route path="/contact" component={<>
//           <Navbar homeRef={homeRef} aboutRef={aboutRef} offerRef={offerRef} clientRef={clientRef} contactRef={contactRef}/>
//           <div ref={contactRef}style={{width:'100%', height:'10px'}}></div>
//           <br/>
//           <Contact id="contact"  contactRef={contactRef}/>
//         </>} />
//         {/* <Route path="*" element={<Navigate to="*" />} /> */}
//       </Routes>
//     </div>
//   );
// }



