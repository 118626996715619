import React, { useState } from 'react';
import { useEffect } from 'react';
import {InputLabel, Link,Container, Typography, Button, Box ,Grid, TextField, useTheme, useMediaQuery, Divider,Autocomplete,MenuItem,Select,FormControl,Alert} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import NavRathLogo from './RATHcha.png'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useLocation,useNavigate } from 'react-router-dom';
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
  } from 'react-simple-maps';
import featuresMap from './features.json'
import countries from './countries';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const useStyles = makeStyles((theme) => ({
    TextField: {
        backgroundColor: 'white',
      '& .MuiInputBase-input::placeholder': {
        color: '#5C4033 !important',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#5C4033',
        },
        '&:hover fieldset': {
          borderColor: '#5C4033',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#5C4033',
        },
        '& input::placeholder': {
          color: '#5C4033',
        },
      },
    },
    lastfooter: {
        display:'flex',
        alignItems:'center',
        justifyContent:'space-evenly',
    },
    lastfooter1: {
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'space-evenly',
    }
  }));

const Contact1 = ({ homeRef, aboutRef, offerRef,contactRef, inputValue1 }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [inputValue, setInputValue] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState({ code: 'IN', label: 'India', phone: '+91' });
    const [countryCode, setCountryCode] = useState('+91');
    const [message, setMessage] = useState('');
    const [location, setLocation] = useState('');
    const [hourlyraterequested,setHourlyraterequested] = useState('');
    // const [company,setCompany] = useState('');
    // const [title, setTitle] = useState('');
    // const [date,setDate] = useState('');
    const [topicsExpertConsultation, setTopicsExpertConsultation] = useState('');
    const [linkedinurl, setLinkedinurl] = useState('');
    const [additionalinformation, setAdditionalinformation] = useState('');
    const [resume, setResume] = useState(null);
    const [datasucess, setDatasucess] = useState(false)
    const [dataerror, setDataerror] = useState(false)

    const currentYear = new Date().getFullYear();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleChangecurrency = (event) => {
        setCurrency(event.target.value);
    };

    const scrollToRef = (ref) => {
        window.scrollTo({
          top: ref.current.offsetTop,
          behavior: 'smooth'
        });
      };

    const navigate = useNavigate();
    const location1 = useLocation();
    const currentRoute = location1.pathname;

    const options = [
        // {label:'Inquiry type'},
        {label:'Become a RATH advisor/ consultant/ expert'},
        // {label:'I am an existing RATH advisor'},
        {label:'Become a RATH client'},
        // {label:'I am an existing RATH client'},
        // {label:'I would like to apply for a position at RATH'},
        // {label:'I would like to submit a new project with RATH'},
    ]

    // const locations = [
    //     { name: 'New York', coordinates: [-74.006, 40.7128], markerOffsety: 12, markerOffsetx: 12 },
    //     { name: 'Chicago', coordinates: [-87.6298, 41.8781] ,markerOffsety: 8, markerOffsetx: -25},
    //     { name: 'Boston', coordinates: [-71.0589, 42.3601], markerOffsety: 0, markerOffsetx: 32 },
    //     { name: 'San Francisco', coordinates: [-122.4194, 37.7749],markerOffsety: 13, markerOffsetx: 42 },
    //     { name: 'Bangalore', coordinates: [77.5946, 12.9716], markerOffsety: 10, markerOffsetx: 0 }
    // ];

    const locations = [
        { name: 'New York', coordinates: [-74.006, 40.7128], markerOffsety: 12, markerOffsetx: 12 },
        { name: 'Bangalore', coordinates: [77.5946, 12.9716], markerOffsety: 10, markerOffsetx: 0 },
        { name: 'Tokyo', coordinates: [139.6917, 35.6895], markerOffsety: 10, markerOffsetx: 0 }, // Coordinates for Tokyo, Japan
        { name: 'Dubai', coordinates: [55.2708, 25.2048], markerOffsety: 10, markerOffsetx: 0 } // Coordinates for Dubai, UAE
    ]

    const [captchaValue, setCaptchaValue] = useState('');
    const [userInput, setUserInput] = useState('');
    // const [isCaptchaValid, setIsCaptchaValid] = useState(true);

    // const generateCaptcha = () => {
    //     const captchaString = Math.random().toString(36).substr(2, 5).toUpperCase();
    //     setCaptchaValue(captchaString);
    // };

    // const handleInputChange = (e) => {
    //     setUserInput(e.target.value);
    //     setIsCaptchaValid(true);
    // };

    const [isValidUrl, setIsValidUrl] = useState(true);
    const [isValidfirstname, setIsValidfirstname] = useState(true);
    const [isValidlastname, setIsValidlastname] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPhoneno, setIsValidPhoneno] = useState(true);

    const handleLinkedinUrlBlur = (e) => {
        if (e.target.value === '') return
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        const url = e.target.value;
        setLinkedinurl(url);
        setIsValidUrl(urlRegex.test(url));
    };
    
    const handleFirstnameBlur = (e) => {
        if (e.target.value === '') return
        const firstnameRegex = /^[A-Za-z]+$/;
        const firstname = e.target.value;
        setFirstName(firstname);
        setIsValidfirstname(firstnameRegex.test(firstname));
    };

    const handleLastnameBlur = (e) => {
        if (e.target.value === '') return
        const lastnameRegex = /^[A-Za-z]+$/;
        const lastname = e.target.value;
        setLastName(lastname);
        setIsValidlastname(lastnameRegex.test(lastname));
    };

    const handleEmailBlur = (e) => {
        if (e.target.value === '') return
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const email = e.target.value;
        setEmail(email);
        setIsValidEmail(emailRegex.test(email));
    };

    const handlePhoneBlur = (e) => {
        if (e.target.value === '') return
        const phoneRegex = /^\+?[0-9\s\-()]+$/;
        const phone = e.target.value;
        setPhone(phone);
        setIsValidPhoneno(phoneRegex.test(phone));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (captchaValue === '') {
            console.log("Form submitted successfully!");
            if(inputValue === 'Become a RATH advisor/ consultant/ expert'){
                const formData = {
                    'firstName':firstName,
                    'lastName':lastName,
                    'email':email,
                    'phone':phone,
                    'countrycode':countryCode,
                    'inquiryType':inputValue, 
                    'location':location,
                    'hourlyRateRequested':hourlyraterequested,
                    'currency':currency,
                    // 'company':company,
                    // 'title':title,
                    // 'date':date,
                    'topicsExpertConsultation':topicsExpertConsultation,
                    'linkedinUrl':linkedinurl,
                    'additionalInformation':additionalinformation,
                    'resume': resume
                    // 'status':'last'
                };
                    
                console.log(formData);
                axios
                    .post("https://rathresearch.com/api/advisor", formData, {
                    headers: { 
                        "Content-Type": "multipart/form-data" ,
                    }
                    })
                    .then((response) => {
                        console.log(response);
                        console.log('Data successfully sent to the server');
                        setFirstName('')
                        setLastName('')
                        setEmail('')
                        setPhone('')
                        setCountry(null)
                        setCountryCode('')
                        setInputValue('')
                        setMessage('')
                        setLocation('')
                        setHourlyraterequested('')
                        setCurrency('')
                        // setCompany('')
                        // setTitle('')
                        // setDate('')
                        setTopicsExpertConsultation('')
                        setLinkedinurl('')
                        setAdditionalinformation('')
                        setCaptchaValue('')
                        // setUserInput('');
                        // generateCaptcha();
                        // setIsCaptchaValid(true);
                        setResume(null)
                        setDatasucess(true)
                        setTimeout(resultmessagesucess, 0);
                        setTimeout(() => resultmessagesucessf(), 5000);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        setTimeout(resultmessageerror, 0);
                        setTimeout(() => resultmessageerrorf(), 5000);
                    });
            }
            else if(inputValue === 'I would like to apply for a position at RATH'){
                console.log(resume)
                // const formData = new FormData();
                const formData = {
                    'firstName':firstName,
                    'lastName':lastName,
                    'email':email,
                    'phone':phone,
                    'countrycode':countryCode,
                    'inputValue':inputValue, 
                    'message':message,
                    'resume': resume
                    // 'status':'middle'
                };
    
                console.log(formData);
                axios
                    .post("https://rathresearch.com/api/positionapplication", formData, {
                    headers: { 
                        "Content-Type": "multipart/form-data" ,
                    }
                    })
                    .then((response) => {
                        console.log(response);
                        console.log('Data successfully sent to the server');
                        setFirstName('')
                        setLastName('')
                        setEmail('')
                        setPhone('')
                        setCountry(null)
                        setCountryCode('')
                        setInputValue('')
                        setMessage('')
                        setCaptchaValue('')
                        // setUserInput('');
                        // generateCaptcha();
                        // setIsCaptchaValid(true);
                        setResume(null)
                        setDatasucess(true)
                        setTimeout(resultmessagesucess, 0);
                        setTimeout(() => resultmessagesucessf(), 5000);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        setTimeout(resultmessageerror, 0);
                        setTimeout(() => resultmessageerrorf(), 5000);
                    });
            }
            else{
                const formData = {
                    'firstName':firstName,
                    'lastName':lastName,
                    'email':email,
                    'phone':phone,
                    'countrycode':countryCode,
                    'inquiryType':inputValue, 
                    'message':message,
                    // 'status':'first'
                };
        
                console.log(formData);
                axios
                    .post("https://rathresearch.com/api/generalinquiry", JSON.stringify(formData), {
                    headers: { 
                        "Content-Type": "application/json" ,
                    }
                    })
                    .then((response) => {
                        console.log(response);
                        console.log('Data successfully sent to the server');
                        setFirstName('')
                        setLastName('')
                        setEmail('')
                        setPhone('')
                        setCountry(null)
                        setCountryCode('')
                        setInputValue('')
                        setMessage('')
                        // setUserInput('');
                        // generateCaptcha();
                        // setIsCaptchaValid(true);
                        setDatasucess(true)
                        setTimeout(resultmessagesucess, 0);
                        setTimeout(() => resultmessagesucessf(), 5000);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        setTimeout(resultmessageerror, 0);
                        setTimeout(() => resultmessageerrorf(), 5000);
                    });
            }
          } else {
            console.log("CAPTCHA validation failed.");
            setCaptchaValue('')
          }
    };

    const handleChange = (event) => {
        setResume(event.target.files[0]);
        console.log(resume)
    };

    useEffect(() => {
        AOS.init({
          duration: 2000,
        });
        setTimeout(() => {
            setInputValue(inputValue1)
          }, 100);
      }, []);

      function resultmessagesucess() {
        setDatasucess(true)
      }
      function resultmessageerror () {
        setDataerror(true)
      }
      function resultmessagesucessf() {
        setDatasucess(false)
      }
      function resultmessageerrorf () {
        setDataerror(false)
      }
      const handleRedirectToHome = () => {
        navigate('/');
      }


  return (
    <div style={{ position:'relative' ,py:'20px'}}>
        <Box sx={{backgroundColor:''}} py={3}>
        <Container maxWidth="lg">
        <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} pt={2} pb={3}>
            <Typography variant="h3"  color='#5C4033' style={{ fontWeight: 'bold', fontFamily:'Kanit, sans-serif' }} gutterBottom>CONTACT US</Typography>
            {/* <Divider sx={{ 
                flexGrow: 1,
                width:'70px',
                maxWidth:'70px',
                borderColor: '#5C4033',
                borderWidth: '2px',
                borderStyle: 'solid',
                marginRight:'10px'
            }}/> */}
            </Box>
            {/* <Grid container >
                <Grid item xs={4} sm={3} md={3}>
                    <Typography variant="subtitle1" color="#5C4033" align="center" 
                    sx={{fontFamily:'Kanit, sans-serif'}}>New York, NY</Typography>
                </Grid>
                <Grid item xs={4} sm={3} md={3}>
                    <Typography variant="subtitle1" color="#5C4033" align="center"
                    sx={{fontFamily:'Kanit, sans-serif'}}>Chicago, IL</Typography>
                </Grid>
                <Grid item xs={4} sm={3} md={3}>
                    <Typography variant="subtitle1" color="#5C4033" align="center"
                    sx={{fontFamily:'Kanit, sans-serif'}}>Boston, MA</Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <Typography variant="subtitle1" color="#5C4033" align="center"
                    sx={{fontFamily:'Kanit, sans-serif'}}>San Francisco, CA</Typography>
                </Grid>
            </Grid> */}
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                {inputValue === 'Become a RATH client' ?
                 <>
                 <Typography variant='h5' color='#5C4033' sx={{fontFamily:'Kanit, sans-serif',fontWeight:600}}>
                    Introducing RATH Research: Your Global Expertise Solution
                 </Typography>
                 <br/>
                 <Typography variant='subtitle1' color='#272D4E' sx={{fontFamily:'Kanit, sans-serif' , textAlign:'justify'}}>
                    Access 50,000+ specialized experts across North America, EMEA, and APAC.
                 </Typography>
                 <br/>
                 <Typography variant='h6' color='#5C4033' sx={{fontFamily:'Kanit, sans-serif',fontWeight:600}}>
                    Unlock C-Level Perspectives, Especially in Tech:
                 </Typography>
                 <Typography variant='subtitle1' color='#272D4E' sx={{fontFamily:'Kanit, sans-serif', textAlign:'justify'}}>
                    Over 80% of our experts hold influential positions, guaranteeing you access to perspectives from the pinnacle of organizational leadership.
                 </Typography>
                 <br/>
                 <Typography variant='h6' color='#5C4033' sx={{fontFamily:'Kanit, sans-serif',fontWeight:600}}>
                    Global Reach, Local Expertise:
                 </Typography>
                 <Typography variant='subtitle1' color='#272D4E' sx={{fontFamily:'Kanit, sans-serif', textAlign:'justify'}}>
                    Our commitment to excellence extends worldwide, with custom recruitment efforts conducted in multiple languages by our dedicated client managers strategically positioned in key locations.
                 </Typography>
                 <br/>
                 <Typography variant='h6' color='#5C4033' sx={{fontFamily:'Kanit, sans-serif',fontWeight:600}}>
                    Compliance You Can Trust:
                 </Typography>
                 <Typography variant='subtitle1' color='#272D4E' sx={{fontFamily:'Kanit, sans-serif', textAlign:'justify'}}>
                    Our rigorous standards ensure that every interaction is conducted with the utmost integrity and professionalism.
                 </Typography>
                 <br/>
                 <Typography variant='h6' color='#5C4033' sx={{fontFamily:'Kanit, sans-serif',fontWeight:600}}>
                    Experience the RATH Advantage:
                 </Typography>
                 <Typography variant='subtitle1' color='#272D4E' sx={{fontFamily:'Kanit, sans-serif', textAlign:'justify'}}>
                    Gain a competitive edge with access to hard-to-find experts, rapid insights, and unwavering compliance.
                 </Typography>
                 <br/>
                 </>
                 
                 :
                    <ComposableMap
                    projection="geoEqualEarth"
                    // projectionConfig={{
                    //     scale: 1100
                    // }}
                    >
                    <Geographies geography={featuresMap}>
                        {({ geographies }) =>
                        geographies.map((geo) => (
                            <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            fill="#EAEAEC"
                            stroke="#D6D6DA"
                            />
                        ))
                        }
                    </Geographies>
                    {locations.map(({ name, coordinates, markerOffsety, markerOffsetx }) => (
                        <Marker key={name} coordinates={coordinates}>
                        <g
                            fill="none"
                            stroke="#FF5533"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            transform="translate(-12, -24)"
                        >
                            <circle cx="12" cy="10" r="3" />
                            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                        </g>
                        <text
                            textAnchor="middle"
                            y={markerOffsety}
                            x={markerOffsetx}
                            style={{ fontFamily: 'Montserrat, sans-serif', fill: "#5D5A6D",fontSize:'15px',fontWeight: 500 }}
                        >
                            {name}
                        </text>
                        </Marker>
                    ))}
                    </ComposableMap>
                }
                </Grid>
                <Grid item xs={12} md={6}>
                <Typography variant="h5" color='#272D4E' pb='5px' gutterBottom 
                sx={{fontFamily:'Kanit, sans-serif'}}>Let's Connect</Typography>
                
                <Typography variant="body1" color='#272D4E' pb='5px' gutterBottom
                sx={{fontFamily:'Kanit, sans-serif'}}>
                    Please provide a few details so we can connect you to the right person.
                </Typography>
                <form autoComplete="off"  encType="multipart/form-data" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField required className={classes.TextField} fullWidth 
                              InputLabelProps={{ style: { color: '#5C4033' }, }} sx={{ height: '50px',borderRadius: '6px' }} 
                              label="First Name" variant="outlined" onChange={(e) => setFirstName(e.target.value)} onBlur={handleFirstnameBlur} value={firstName}
                              error={!isValidfirstname} helperText={!isValidfirstname ? "Invalid First Name" : ""}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField  className={classes.TextField} fullWidth
                             InputLabelProps={{ style: { color: '#5C4033' }, }} sx={{ height: '50px',borderRadius: '6px' }} 
                             label="Last Name" variant="outlined" onChange={(e) => setLastName(e.target.value)} onBlur={handleLastnameBlur} value={lastName}
                             error={!isValidlastname} helperText={!isValidlastname ? "Invalid Last Name" : ""}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField required className={classes.TextField} fullWidth margin="normal"
                             InputLabelProps={{ style: { color: '#5C4033' }, }} sx={{ height: '50px',borderRadius: '6px' }} 
                             label="E-mail" variant="outlined" onChange={(e) => setEmail(e.target.value)} onBlur={handleEmailBlur} value={email}
                             error={!isValidEmail} helperText={!isValidEmail ? "Invalid Email" : ""}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <Autocomplete
                            id="country-select-demo"
                            options={countries}
                            autoHighlight
                            getOptionLabel={(option) => ` ${option.code} ${option.phone} `}
                            value={country}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {/* <img
                                    loading="lazy"
                                    width="20"
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    alt=""
                                /> */}
                                {option.label} 
                                {/* ({option.code})  */}
                                {option.phone}
                                </Box>
                            )}
                            onChange={(event, newValue) => {
                                setCountry(newValue);
                                setCountryCode(newValue ? newValue.phone : '');
                                console.log(country)
                                console.log(countryCode)

                            }}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                margin="normal"
                                className={classes.TextField}
                                label="Country Code"
                                InputLabelProps={{
                                    style: { color: '#5C4033' }, // Replace '#yourColor' with your desired color
                                }}
                                value={countryCode}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                />
                            )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField  className={classes.TextField} fullWidth margin="normal"
                             InputLabelProps={{ style: { color: '#5C4033' }, }} sx={{ height: '50px',borderRadius: '6px' }} 
                             label="Phone No." variant="outlined" onChange={(e) => setPhone(e.target.value)} onBlur={handlePhoneBlur} value={phone}
                             error={!isValidPhoneno} helperText={!isValidPhoneno ? "Invalid Phone No" : ""}/>
                        </Grid>
                        <Grid item xs={12} mt='1px'>
                        {/* <Autocomplete
                            disablePortal
                            required
                            id="combo-box-demo"
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                                console.log(newInputValue) 
                            }}
                            options={options}
                            fullWidth
                            sx={{ height: '50px',borderRadius: '6px' }}
                            renderInput={(params) => <TextField className={classes.TextField} value={inputValue}  {...params} placeholder="Inquiry type*" />}
                        /> */}
                        <FormControl fullWidth className={classes.TextField} required>
                            <Select
                                value={inputValue}
                                onChange={(event) => {
                                setInputValue(event.target.value);
                                console.log(event.target.value);
                                }}
                                displayEmpty
                                inputProps={{ id: 'combo-box-demo' }}
                                sx={{ height: '50px', borderRadius: '6px', color: '#5C4033' }}
                                MenuProps={{
                                PaperProps: {
                                    style: {
                                    maxHeight: '50vh', // Maximum height before scrolling
                                    overflow: 'auto', // Enable scrolling
                                    },
                                },
                                }}
                            >
                                <MenuItem value="" disabled>
                                Select Inquiry Type*
                                </MenuItem>
                                {options.map((option) => (
                                <MenuItem key={option.label} value={option.label}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        </Grid>
                        {inputValue === 'I am an existing RATH advisor' | inputValue === 'Become a RATH client' | inputValue === 'I am an existing RATH client' | inputValue === 'I would like to submit a new project with RATH' ?
                        <Grid item xs={12}>
                        <TextField
                            className={classes.TextField}
                            InputLabelProps={{ style: { color: '#5C4033' }, }}
                            // required
                            fullWidth
                            onChange={(e) => setMessage(e.target.value)}
                            name="message" 
                            type="text" 
                            label='Message'
                            // placeholder="Message"
                            variant="outlined"
                            value={message}
                            multiline
                            rows={2}
                            margin="normal"
                            style={{ borderRadius: '6px' }}
                        />
                        </Grid>
                        :
                        <></>}
                        {inputValue === 'I would like to apply for a position at RATH' ?
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.TextField}
                                    InputLabelProps={{ style: { color: '#5C4033' }, }}
                                    // required
                                    fullWidth
                                    onChange={(e) => setMessage(e.target.value)}
                                    name="message" 
                                    type="text" 
                                    label='Message'
                                    // placeholder="Message"
                                    variant="outlined"
                                    multiline
                                    value={message}
                                    rows={2}
                                    margin="normal"
                                    style={{ borderRadius: '6px' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button component="label" variant="contained" sx={{backgroundColor: '#b58b62', color: 'white'}} startIcon={<CloudUploadIcon />}>
                                    Upload Resume
                                    <VisuallyHiddenInput 
                                        type="file" 
                                        accept='.pdf'
                                        name="resume" // Changed name attribute
                                        onChange={handleChange} 
                                    />
                                </Button>
                            </Grid>
                        </>
                        :
                        <></>}
                        {inputValue === 'Become a RATH advisor/ consultant/ expert' ?
                        <>
                            <Grid item xs={12}>
                                <Typography variant="body2" gutterBottom>The following form will ensure we fully understand your background and match you with relevant projects.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField  className={classes.TextField} 
                                 InputLabelProps={{ style: { color: '#5C4033' }, }} sx={{maxWidth:'300px', height: '50px',borderRadius: '6px' }} 
                                 label="Location" variant="outlined" onChange={(e) => setLocation(e.target.value)} value={location}/>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField  className={classes.TextField} fullWidth margin="normal"
                                 InputLabelProps={{ style: { color: '#5C4033' }, }} sx={{ height: '50px',borderRadius: '6px' }} value={hourlyraterequested}
                                 label="Hourly Rate Requested" variant="outlined" onChange={(e) => setHourlyraterequested(e.target.value)}/>
                            </Grid>
                            <Grid item xs={5} mt={2}>
                            <FormControl fullWidth className={classes.TextField}>
                                <Select
                                    displayEmpty
                                    id="currency"
                                    sx={{ color: '#5C4033' }} // Your color styling
                                    value={currency}
                                    onChange={handleChangecurrency}
                                    renderValue={selected => {
                                    if (selected === '') {
                                        return <em>Currency</em>; // Replace 'Placeholder' with your placeholder text
                                    }

                                    return selected;
                                    }}
                                >
                                    <MenuItem disabled value="">
                                    <em>Currency</em> {/* Replace 'Placeholder' with your placeholder text */}
                                    </MenuItem>
                                    <MenuItem value={'INR'}>INR</MenuItem>
                                    <MenuItem value={'EUR'}>EUR</MenuItem>
                                    <MenuItem value={'USD'}>USD</MenuItem>
                                    <MenuItem value={'AED'}>AED</MenuItem>
                                    <MenuItem value={'JPY'}>JPY</MenuItem>
                                </Select>
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} sm={4}>
                                <TextField required className={classes.TextField} fullWidth 
                                 InputLabelProps={{ style: { color: '#5C4033' }, }} sx={{ height: '50px',borderRadius: '6px' }} 
                                 label="Company" variant="outlined" onChange={(e) => setCompany(e.target.value)} value={company}/>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField required className={classes.TextField} fullWidth 
                                 InputLabelProps={{ style: { color: '#5C4033' }, }} sx={{ height: '50px',borderRadius: '6px' }} 
                                 label="Title" variant="outlined" onChange={(e) => setTitle(e.target.value)} value={title}/>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextField required className={classes.TextField} fullWidth 
                                 InputLabelProps={{ style: { color: '#5C4033' }, }} sx={{ height: '50px',borderRadius: '6px' }} 
                                 label="Dates(mm/yyyy - mm/yyyy)" variant="outlined" onChange={(e) => setDate(e.target.value)} value={date}/>
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.TextField}
                                    InputLabelProps={{ style: { color: '#5C4033' }, }}
                                    // required
                                    fullWidth
                                    onChange={(e) => setTopicsExpertConsultation(e.target.value)}
                                    name="topicsexpertconsultation" 
                                    type="text" 
                                    label='Topics you provide Expert Consultation'
                                    // placeholder="Comapny & Role Descrpition"
                                    variant="outlined"
                                    value={topicsExpertConsultation}
                                    multiline
                                    rows={2}
                                    margin="normal"
                                    style={{ borderRadius: '6px' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField className={classes.TextField} fullWidth 
                                 InputLabelProps={{ style: { color: '#5C4033' }, }} sx={{ height: '50px',borderRadius: '6px' }} 
                                 label="Linkedin Url" variant="outlined" onChange={(e) => setLinkedinurl(e.target.value)} onBlur={handleLinkedinUrlBlur} 
                                 value={linkedinurl} error={!isValidUrl} helperText={!isValidUrl ? "Invalid URL" : ""}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.TextField}
                                    InputLabelProps={{ style: { color: '#5C4033' }, }}
                                    // required
                                    fullWidth
                                    onChange={(e) => setAdditionalinformation(e.target.value)}
                                    name="message" 
                                    type="text" 
                                    label='Additional Information'
                                    // placeholder="Additional Information"
                                    variant="outlined"
                                    value={additionalinformation}
                                    multiline
                                    rows={2}
                                    margin="normal"
                                    style={{ borderRadius: '6px' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button component="label" variant="contained" sx={{backgroundColor: '#b58b62', color: 'white',fontFamily:'Montserrat, sans-serif' }} startIcon={<CloudUploadIcon />}>
                                    Upload Resume
                                    <VisuallyHiddenInput 
                                        type="file"
                                        accept='.pdf' 
                                        name="resume" // Changed name attribute
                                        onChange={handleChange} 
                                    />
                                </Button>
                            </Grid>
                        </>
                        :
                        <></>}
                        <Grid item xs={12} sx={{display:'flex', justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                            {/* <Typography variant='subtitle1' sx={{color: '#5C4033', fontFamily:'Montserrat, sans-serif' }}>
                                <b> CAPTCHA: {captchaValue}</b>
                            </Typography> */}
                            <div style={{display: 'none'}}>
                                <label for="honeypot">Leave this field blank:</label>
                                <input type="text" id="honeypot" name="honeypot" onChange={(e) => setCaptchaValue(e.target.value)}/>
                            </div>
                        {/* </Grid>
                        <Grid item xs={7}>
                            <TextField
                                required
                                label="Enter the CAPTCHA"
                                variant="outlined"
                                className={classes.TextField}
                                InputLabelProps={{ style: { color: '#5C4033' }, }}
                                value={userInput}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            /> */}
                        </Grid>
                        {/* <Grid item xs={12}>
                            {!isCaptchaValid && (
                                <Alert severity="error" style={{ marginBottom: '16px',fontFamily:'Montserrat, sans-serif' }}>
                                    CAPTCHA validation failed. Please try again.
                                </Alert>
                            )}
                        </Grid> */}
                            {datasucess? <Grid item xs={12}> <Alert severity="success" sx={{fontFamily:'Kanit, sans-serif'}}>Your message has been transmitted successfully. We'll be in touch with you shortly.</Alert></Grid>:<></>}
                            {dataerror? <Grid item xs={12}><Alert severity="error" sx={{fontFamily:'Kanit, sans-serif'}}>Apologies for the inconvenience. Please attempt to submit your information again at a later time. Thank you for your patience.</Alert></Grid>:<></>}
                        <Grid item xs={12}>
                        <Button
                        type="submit"
                        variant="contained"
                        size='large'
                        // disabled={!isValidUrl? false :true}
                        style={{ px:'10px',backgroundColor: '#b58b62', color: 'white', marginTop: '10px',borderRadius:'20px',fontFamily:'Kanit, sans-serif' }}
                        >
                                Submit
                            </Button>
                        </Grid>
                        </Grid>
                </form>
                </Grid>
            </Grid>
            </Container>
        </Box>
        <Box sx={{
            backgroundColor: '#5C4033', 
            color: 'white', 
            boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.1)', 
            textAlign: 'center',
            padding: '60px 0px 0px 0px'
        }}>
            <Container maxWidth="lg" >
            <Grid container justifyContent="center" alignItems="flex-start" >
                <Grid item xs={12} sm={5} md={6} pb={4} pl={2}>
                    <Box sx={{display:'flex',flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start'}}>
                        <img src={NavRathLogo} alt="RATH logo" width='250px' height='80px'></img>
                        <br/>
                        <Button variant="outlined" onClick={() => { scrollToRef(contactRef); setInputValue('Become a RATH client'); }} 
                        style={{ fontFamily:'Kanit, sans-serif', color: 'white', width:'200px', borderColor: 'white', '&:hover': { borderColor: 'white', backgroundColor: 'white'}  }} >
                            Become a Client
                        </Button>
                        <br/>
                        <Button variant="outlined" onClick={() => { scrollToRef(contactRef); setInputValue('Become a RATH advisor/ consultant/ expert'); }}
                        style={{ fontFamily:'Kanit, sans-serif', color: 'white', width:'200px', borderColor: 'white', '&:hover': { borderColor: 'white', backgroundColor: 'white'}  }} >
                            Become an expert 
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3} md={2} pb={4} pl={2}>
                    <Box sx={{display:'flex',flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start'}}>
                        <Typography variant="h5" color="white" pb={1} fontWeight={600}>SITE MAP</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{cursor:'pointer', fontFamily:'Kanit, sans-serif'}} {...currentRoute === '/contact' ? {onClick: () => { handleRedirectToHome(); setTimeout(() => scrollToRef(homeRef), 200); }} : {onClick: () => scrollToRef(homeRef)}}>Home</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{cursor:'pointer', fontFamily:'Kanit, sans-serif'}} {...currentRoute === '/contact' ? {onClick: () => { handleRedirectToHome(); setTimeout(() => scrollToRef(aboutRef), 200); }} : {onClick: () => scrollToRef(homeRef)}}>About</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{cursor:'pointer', fontFamily:'Kanit, sans-serif'}} {...currentRoute === '/contact' ? {onClick: () => { handleRedirectToHome(); setTimeout(() => scrollToRef(offerRef), 200); }} : {onClick: () => scrollToRef(homeRef)}}>Services</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{cursor:'pointer', fontFamily:'Kanit, sans-serif'}} onClick={() =>{ scrollToRef(contactRef);}}>Contact</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4} pb={4} pl={2}>
                    <Box sx={{display:'flex',flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start'}}>
                    <Typography variant="h5" color="white" align="center" pb={1} fontWeight={600} sx={{fontFamily:'Kanit, sans-serif'}}>CONTACT US</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{fontFamily:'Montserrat, sans-serif'}}>Address</Typography>
                        <Typography variant="subtitle1" color="white" align="center" sx={{fontFamily:'Kanit, sans-serif'}}>Rath Research Head  Office : </Typography> 
                        <Typography variant="subtitle1" color="white" align="left" mb={1} sx={{fontFamily:'Kanit, sans-serif'}}> 
                        Orchid Techspace, STPI-Bengaluru, No.76 & 77, 6th Floor,Cyber Park,Electronics City, Hosur Road, Bengaluru, 
                        Karnataka - 560100</Typography>
                        <Typography variant="h6" color="white" align="center" sx={{fontFamily:'Kanit, sans-serif'}}>Telephone</Typography>
                        {/* <Link href="tel:+918423257747" underline="none">
                            <Typography variant="subtitle1" color="white" align="center" mb={1} sx={{fontFamily:'Montserrat, sans-serif'}}>+918423257747</Typography>
                        </Link> */}
                        <Link href="tel:+918004006979" underline="none">
                            <Typography variant="subtitle1" color="white" align="center" mb={1} sx={{fontFamily:'Kanit, sans-serif'}}>+918004006979</Typography>
                        </Link>
                        <Typography variant="h6" color="white" align="center" sx={{fontFamily:'Kanit, sans-serif'}}>Email</Typography>
                        <Link href="mailto:contact@rathresearch.com" underline="none">
                        <Typography variant="subtitle1" color="white" align="center" mb={1} sx={{ fontFamily: 'Kanit, sans-serif' }}>
                            contact@rathresearch.com
                        </Typography>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            </Container>            
            <Box component="footer" py={3}  sx={{backgroundColor:'rgba(0,0,0,0.2)'}} >
                <Box px={3} className={isMobile ? classes.lastfooter1 : classes.lastfooter}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography variant="body2" color="lightgrey" align="center" 
                            sx={{fontFamily:'Montserrat, sans-serif'}}>
                                {/* © {currentYear} CODEBUGGED. Organization Pvt. Ltd.
                                Designed & Developed by Codebugged AI */}
                                Copyright © RATH RESEARCH {currentYear}. All Rights Reserved.
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={2} md={1}>
                            <Typography variant="body2" color="lightgrey" align="center" 
                            sx={{fontFamily:'Montserrat, sans-serif'}}>Bangalore</Typography>
                        </Grid>
                        <Grid item xs={4} sm={2} md={1}>
                            <Typography variant="body2" color="lightgrey" align="center"
                            sx={{fontFamily:'Montserrat, sans-serif'}}>Dubai</Typography>
                        </Grid>
                        <Grid item xs={4} sm={2} md={1}>
                            <Typography variant="body2" color="lightgrey" align="center"
                            sx={{fontFamily:'Montserrat, sans-serif'}}>New York</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={1}>
                            <Typography variant="body2" color="lightgrey" align="center"
                            sx={{fontFamily:'Montserrat, sans-serif'}}>Tokyo</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
     </div>
  );
};

export default Contact1;